import React, { lazy, Suspense, useState, useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import { useDispatch, useSelector } from "react-redux";
import { getPermissionListState } from "redux/reducers/permissions";
import ErrorBoundary from "components/shared-components/ErrorBoundary";
import { io } from "socket.io-client";
import { socketConnection } from "redux/reducers/socket";
import { env } from "configs/EnvironmentConfig";

export const AppViews = () => {
  const dispatch = useDispatch();
  const [permission, setPermissions] = useState(
    JSON.parse(localStorage.getDecryptedItem("p_m__"))
  );
  const data = useSelector(getPermissionListState);
  useEffect(() => {
    setPermissions(JSON.parse(localStorage.getDecryptedItem("p_m__")));
  }, [data]);
  let checkPermissions = (checkValue) => {
    if (permission?.some((e) => e.code === checkValue)) {
      return true;
    } else {
      return false;
    }
  };
  useEffect(() => {
    const socket = io(env.API_ENDPOINT_URL);
    dispatch(socketConnection(socket));
    const subscriberId = localStorage.getItem("subscriberId");
    if (subscriberId) {
      socket.emit("addUser", subscriberId);
    }
    return () => {
      socket.disconnect();
    };
  }, [dispatch]);

  return (
    <ErrorBoundary>
      <Suspense fallback={<Loading cover="content" />}>
        <Switch>
          <Route
            path={`${APP_PREFIX_PATH}/home`}
            component={lazy(() => import(`./home-button`))}
          />
          <GuardedRoute
            path={`${APP_PREFIX_PATH}/manage-user`}
            component={lazy(() => import(`./manage-user`))}
            hasAccesssToRoute={checkPermissions("MANAGE_USER")}
          ></GuardedRoute>
          <GuardedRoute
            path={`${APP_PREFIX_PATH}/manage-role`}
            component={lazy(() => import(`./manage-role`))}
            hasAccesssToRoute={checkPermissions("MANAGE_ROLE")}
          ></GuardedRoute>
          <GuardedRoute
            path={`${APP_PREFIX_PATH}/manage-feature`}
            component={lazy(() => import(`./manage-feature`))}
            hasAccesssToRoute={checkPermissions("MANAGE_FEATURE")}
          ></GuardedRoute>
          <GuardedRoute
            path={`${APP_PREFIX_PATH}/manage-privilege`}
            component={lazy(() => import(`./manage-privilege`))}
            hasAccesssToRoute={checkPermissions("MANAGE_PRIVILEGES")}
          ></GuardedRoute>
          <GuardedRoute
            path={`${APP_PREFIX_PATH}/feactivity`}
            component={lazy(() => import(`./feactivity`))}
            hasAccesssToRoute={checkPermissions("FE_ACTIVITY")}
          ></GuardedRoute>
          <GuardedRoute
            path={`${APP_PREFIX_PATH}/ticket`}
            component={lazy(() => import(`./ticket`))}
            hasAccesssToRoute={checkPermissions("TICKET_LIST")}
          ></GuardedRoute>
          <GuardedRoute
            path={`${APP_PREFIX_PATH}/service-provider`}
            component={lazy(() => import(`./service-provider`))}
            hasAccesssToRoute={checkPermissions("SERVICE_PROVIDER")}
          ></GuardedRoute>
          <GuardedRoute
            path={`${APP_PREFIX_PATH}/manage-subscriber`}
            component={lazy(() => import(`./manage-subscriber`))}
            hasAccesssToRoute={
              localStorage.getItem("role") !== "USER" &&
              checkPermissions("MANAGE_SUBSCRIBER")
            }
          ></GuardedRoute>
          <GuardedRoute
            path={`${APP_PREFIX_PATH}/site-manage`}
            component={lazy(() => import(`./site-manage`))}
            hasAccesssToRoute={checkPermissions("SITE_MANAGE")}
          ></GuardedRoute>
          <GuardedRoute
            path={`${APP_PREFIX_PATH}/extinguisher-list`}
            component={lazy(() => import(`./extinguisher-list`))}
            hasAccesssToRoute={checkPermissions("FIRE_EXTINGUISHER")}
          ></GuardedRoute>
          <GuardedRoute
            path={`${APP_PREFIX_PATH}/dashboard`}
            component={lazy(() => import(`./dashboard`))}
            hasAccesssToRoute={true}
          ></GuardedRoute>
          <GuardedRoute
            path={`${APP_PREFIX_PATH}/sign`}
            component={lazy(() => import(`./sign`))}
            hasAccesssToRoute={true}
          ></GuardedRoute>
          <GuardedRoute
            path={`${APP_PREFIX_PATH}/dynamic-dashboard`}
            component={lazy(() => import(`./dynamic-board`))}
            hasAccesssToRoute={true}
          ></GuardedRoute>
          <GuardedRoute
            path={`${APP_PREFIX_PATH}/serve-dashboard`}
            component={lazy(() => import(`./serve-dashboard`))}
            hasAccesssToRoute={checkPermissions("SERVE_DASHBAORD")}
          ></GuardedRoute>
          <GuardedRoute
            path={`${APP_PREFIX_PATH}/resource-view`}
            component={lazy(() => import(`./resourceview`))}
            hasAccesssToRoute={checkPermissions("RESOURCE_VIEW")}
          ></GuardedRoute>
          <GuardedRoute
            path={`${APP_PREFIX_PATH}/qr-code-encoder`}
            component={lazy(() => import(`./qr-code-encoder`))}
            hasAccesssToRoute={true}
          ></GuardedRoute>
          <GuardedRoute
            path={`${APP_PREFIX_PATH}/client`}
            component={lazy(() => import(`./client`))}
            hasAccesssToRoute={checkPermissions("CLIENT")}
          ></GuardedRoute>
          <GuardedRoute
            path={`${APP_PREFIX_PATH}/genericTicket`}
            component={lazy(() => import(`./genericTicket`))}
            hasAccesssToRoute={checkPermissions("GENERIC_TICKET_LIST")}
          ></GuardedRoute>
          <GuardedRoute
            path={`${APP_PREFIX_PATH}/preventive-maintenance`}
            component={lazy(() => import(`./preventive-maintenance`))}
            hasAccesssToRoute={checkPermissions(
              "PREVENTIVE_MAINTENANCE_TICKET"
            )}
          ></GuardedRoute>
          <GuardedRoute
            path={`${APP_PREFIX_PATH}/preSaleMaintenance`}
            component={lazy(() => import(`./preSaleMaintenance`))}
            hasAccesssToRoute={checkPermissions("PRESALE_MAINTENANCE_TICKET")}
          ></GuardedRoute>
          <GuardedRoute
            path={`${APP_PREFIX_PATH}/installation-commissioning`}
            component={lazy(() => import(`./installation-commissioning`))}
            hasAccesssToRoute={checkPermissions(
              "INSTALLATION_AND_COMMISSIONING"
            )}
          ></GuardedRoute>
          <GuardedRoute
            path={`${APP_PREFIX_PATH}/breakdownMaintenence`}
            component={lazy(() => import(`./breakdownMaintenence`))}
            hasAccesssToRoute={checkPermissions("BREAKDOWN_MAINTENENCE")}
          ></GuardedRoute>
          <GuardedRoute
            path={`${APP_PREFIX_PATH}/Expenses`}
            component={lazy(() => import(`./Expenses`))}
            hasAccesssToRoute={checkPermissions("SVC_EXPENSES")}
          ></GuardedRoute>
          <GuardedRoute
            path={`${APP_PREFIX_PATH}/svc-machine-type`}
            component={lazy(() => import(`./svc-machine-type`))}
            hasAccesssToRoute={checkPermissions("SVC_MACHINE")}
          ></GuardedRoute>
          <GuardedRoute
            path={`${APP_PREFIX_PATH}/svc-machine-instance`}
            component={lazy(() => import(`./svc-machine-instance`))}
            hasAccesssToRoute={checkPermissions("SVC_MACHINE_INSTANCE")}
          ></GuardedRoute>
          <GuardedRoute
            path={`${APP_PREFIX_PATH}/manage-spare-part`}
            component={lazy(() => import(`./manage-spare-part`))}
            hasAccesssToRoute={checkPermissions("MANAGE_SPARE_PART")}
          ></GuardedRoute>
          <GuardedRoute
            path={`${APP_PREFIX_PATH}/ticket-report-list`}
            component={lazy(() => import(`./ticket-report-list`))}
            hasAccesssToRoute={checkPermissions("TICKET_REPORT_LIST")}
          ></GuardedRoute>
          <GuardedRoute
            path={`${APP_PREFIX_PATH}/ticket-client-list`}
            component={lazy(() => import(`./ticket-client-list`))}
            hasAccesssToRoute={checkPermissions("CLIENT_TICKET")}
          ></GuardedRoute>
          <GuardedRoute
            path={`${APP_PREFIX_PATH}/ticket-assigned-to-me`}
            component={lazy(() => import(`./ticket-assigned-to-me`))}
            hasAccesssToRoute={checkPermissions("TICKET_ASSIGNED_TO_ME")}
          ></GuardedRoute>
          <GuardedRoute
            path={`${APP_PREFIX_PATH}/questions`}
            component={lazy(() => import(`./questions`))}
            hasAccesssToRoute={checkPermissions("QUESTIONS")}
          ></GuardedRoute>
          <GuardedRoute
            path={`${APP_PREFIX_PATH}/actionTicket`}
            component={lazy(() => import(`./actionTicket`))}
            hasAccesssToRoute={checkPermissions("ACTION_")}
          ></GuardedRoute>
          <GuardedRoute
            path={`${APP_PREFIX_PATH}/ticket-dashbaord`}
            component={lazy(() => import(`./ticket-dashboard`))}
            hasAccesssToRoute={true}
          ></GuardedRoute>
          <GuardedRoute
            path={`${APP_PREFIX_PATH}/request-spareparts`}
            component={lazy(() => import(`./sparepart-request`))}
            hasAccesssToRoute={checkPermissions("SPAREPART_REQUEST")}
          ></GuardedRoute>
          <GuardedRoute
            path={`${APP_PREFIX_PATH}/bills`}
            component={lazy(() => import(`./bills`))}
            hasAccesssToRoute={checkPermissions("BILLS")}
          ></GuardedRoute>
          <GuardedRoute
            path={`${APP_PREFIX_PATH}/generate-invoice`}
            component={lazy(() => import(`./generate-invoice`))}
            hasAccesssToRoute={checkPermissions("GENERATE_INVOICE")}
          ></GuardedRoute>
          <GuardedRoute
            path={`${APP_PREFIX_PATH}/generate-e-invoice`}
            component={lazy(() => import(`./generate-e-invoice`))}
            hasAccesssToRoute={checkPermissions("GENERATE_E_INVOICE")}
          ></GuardedRoute>
          <GuardedRoute
            path={`${APP_PREFIX_PATH}/generateSupplementaryInvoice`}
            component={lazy(() => import(`./generateSupplementaryInvoice`))}
            hasAccesssToRoute={checkPermissions("GENERATE_BILLS_INVOICE")}
          ></GuardedRoute>
          <GuardedRoute
            path={`${APP_PREFIX_PATH}/final-invoice-list`}
            component={lazy(() => import(`./final-invoice-list`))}
            hasAccesssToRoute={checkPermissions("FINAL_INVOICE_LIST")}
          ></GuardedRoute>
          <GuardedRoute
            path={`${APP_PREFIX_PATH}/generateCreditNote`}
            component={lazy(() => import(`./generateCreditNote`))}
            hasAccesssToRoute={checkPermissions("GENERATE_CREDIT_NOTE")}
          ></GuardedRoute>
          <GuardedRoute
            path={`${APP_PREFIX_PATH}/manageCreditNote`}
            component={lazy(() => import(`./manageCreditNote`))}
            hasAccesssToRoute={checkPermissions("MANAGE_CREDIT_NOTE")}
          ></GuardedRoute>
          <GuardedRoute
            path={`${APP_PREFIX_PATH}/client-master`}
            component={lazy(() => import(`./client-master`))}
            hasAccesssToRoute={checkPermissions("CLIENT_MASTER")}
          ></GuardedRoute>
          <GuardedRoute
            path={`${APP_PREFIX_PATH}/manual-payment`}
            component={lazy(() => import(`./payments/mannual-payment`))}
            hasAccesssToRoute={checkPermissions("PAYMENT")}
          ></GuardedRoute>
          <GuardedRoute
            path={`${APP_PREFIX_PATH}/invoice-item-master`}
            component={lazy(() => import(`./invoice-item-master`))}
            hasAccesssToRoute={checkPermissions("INVOICE_ITEM_MASTER")}
          ></GuardedRoute>
          <GuardedRoute
            path={`${APP_PREFIX_PATH}/my-open-tickets`}
            component={lazy(() => import(`./ticket-report-list`))}
            hasAccesssToRoute={checkPermissions("MY_OPEN_CALLS")}
          ></GuardedRoute>
          <GuardedRoute
            path={`${APP_PREFIX_PATH}/my-urgent-tickets`}
            component={lazy(() => import(`./ticket-report-list`))}
            hasAccesssToRoute={checkPermissions("MY_URGENT_CALLS")}
          ></GuardedRoute>
          <GuardedRoute
            path={`${APP_PREFIX_PATH}/my-resource-view`}
            component={lazy(() => import(`./resourceview`))}
            hasAccesssToRoute={checkPermissions("MY_CALENDER")}
          ></GuardedRoute>
          <GuardedRoute
            path={`${APP_PREFIX_PATH}/urgent-calls`}
            component={lazy(() => import(`./ticket-report-list`))}
            hasAccesssToRoute={checkPermissions("URGENT_CALLS")}
          ></GuardedRoute>
          <GuardedRoute
            path={`${APP_PREFIX_PATH}/closed-calls`}
            component={lazy(() => import(`./ticket-report-list`))}
            hasAccesssToRoute={checkPermissions("CLOSED_CALLS")}
          ></GuardedRoute>
          <GuardedRoute
            path={`${APP_PREFIX_PATH}/open-calls`}
            component={lazy(() => import(`./ticket-report-list`))}
            hasAccesssToRoute={checkPermissions("OPEN_CALLS")}
          ></GuardedRoute>
          <Route
            path={`${APP_PREFIX_PATH}/fire-extinguisher`}
            component={lazy(() => import(`./fire-extinguisher`))}
          />
          <GuardedRoute
            path={`${APP_PREFIX_PATH}/subscription-request-list`}
            component={lazy(() => import(`./subscription-request-list`))}
            hasAccesssToRoute={checkPermissions("SUBSCRIPTION_REQUESTS_LIST")}
          ></GuardedRoute>
          <GuardedRoute
            path={`${APP_PREFIX_PATH}/salespipeline`}
            component={lazy(() => import(`./sales-pipeline`))}
            hasAccesssToRoute={checkPermissions("SALES-PIPELINE")}
          ></GuardedRoute>
          <GuardedRoute
            path={`${APP_PREFIX_PATH}/client-spoc`}
            component={lazy(() => import(`./client-spoc`))}
            hasAccesssToRoute={checkPermissions("CLIENT_SPOC")}
          ></GuardedRoute>
          <GuardedRoute
            path={`${APP_PREFIX_PATH}/supplier`}
            component={lazy(() => import(`./supplier`))}
            hasAccesssToRoute={checkPermissions("SUPPLIER")}
          ></GuardedRoute>
          <GuardedRoute
            path={`${APP_PREFIX_PATH}/dashboard-ui`}
            component={lazy(() => import(`./dahsbordUI`))}
            hasAccesssToRoute={true}
          ></GuardedRoute>
          <GuardedRoute
            path={`${APP_PREFIX_PATH}/dashboard-realtime`}
            component={lazy(() => import(`./dashboard-realtime`))}
            hasAccesssToRoute={true}
          ></GuardedRoute>{" "}
          <GuardedRoute
            path={`${APP_PREFIX_PATH}/dashboard-machine`}
            component={lazy(() => import(`./machine-dashabord`))}
            hasAccesssToRoute={true}
          ></GuardedRoute>
          <GuardedRoute
            path={`${APP_PREFIX_PATH}/machine-scanning`}
            component={lazy(() => import(`./machine-scanning`))}
            hasAccesssToRoute={true}
          ></GuardedRoute>
          <Route
            path={`${APP_PREFIX_PATH}/bom-tree`}
            component={lazy(() => import(`./bom-tree`))}
          />
          <GuardedRoute
            path={`${APP_PREFIX_PATH}/manage-requisition`}
            component={lazy(() => import(`./manage-requisition`))}
            hasAccesssToRoute={checkPermissions("MANAGE_REQUISITION")}
          ></GuardedRoute>
          <GuardedRoute
            path={`${APP_PREFIX_PATH}/manage-review-requisition`}
            component={lazy(() => import(`./manage-review-requisition`))}
            hasAccesssToRoute={checkPermissions("REVIEW_REQUISITION")}
          ></GuardedRoute>
          <GuardedRoute
            path={`${APP_PREFIX_PATH}/manage-po`}
            component={lazy(() => import(`./manage-po`))}
            hasAccesssToRoute={checkPermissions("MANAGE_PO")}
          ></GuardedRoute>
          <GuardedRoute
            path={`${APP_PREFIX_PATH}/organizationScreen`}
            component={lazy(() => import(`./organizationScreen`))}
            hasAccesssToRoute={checkPermissions("MANAGE_ORGANIZATION")}
          ></GuardedRoute>
          <GuardedRoute
            path={`${APP_PREFIX_PATH}/serviceXpertDashboard`}
            component={lazy(() => import(`./serviceXpertDashboard`))}
            hasAccesssToRoute={true}
          ></GuardedRoute>
          <Route
            path={`${APP_PREFIX_PATH}/graphURLDashboard`}
            component={lazy(() => import(`./graphURLDashboard`))}
          />
          <Redirect
            from={`${APP_PREFIX_PATH}`}
            to={`${APP_PREFIX_PATH}/home`}
          />
        </Switch>
      </Suspense>
    </ErrorBoundary>
  );
};

const GuardedRoute = ({ hasAccesssToRoute, ...rest }) =>
  hasAccesssToRoute ? (
    <Route {...rest} />
  ) : (
    <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/home`} />
  );
export default React.memo(AppViews);
