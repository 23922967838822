import { combineReducers } from "redux";
import LoginResponse from "./login";
import Theme from "./Theme";
import ManageRoleReducer from "./manageRole";
import ManageUserReducer from "./manageUser";
import FeatureReducer from "./feature";
import Permissions from "./permissions";
import SideMenuSlice from "./getSideMenu";
import Privilege from "./privileges";
import SensorProduct from "./sensorProduct";
import SensorInstance from "./sensorInstance";
import SensorData from "./sensorData";
import SensorAnalytics from "./sensorAnalytics";
import CqrsData from "./cqrsData";
import notification from "./notification";
import client from "./client";
import ticket from "./ticket";
import masterConfig from "./masterConfig";
import ManageSubscribersReducer from "./manageSubscriber";
import ServiceProviderReducer from "./serviceProvider";
import Document from "./document";
import feActivity from "./feActivity";
import device from "./device";
import questions from "./questions";
import FireExtinguisherReducer from "./fireExtinguisher";
import Location from "./location";
import organization from "./organization";
import assetType from "./assetType";
import plant from "./plant";
import unit from "./unit";
import siteManage from "./siteManage";
import asset from "./asset";
import systemticketSlice from "./systemTicket";
import sensorInstanceUnit from "./sensorInstanceUnit";
import preventiveMaintenance from "./preventiveMaintenance";
import preSaleMaintenence from "./preSaleMaintenence";
import machine from "./machine";
import spareParts from "./spareParts";
import checklist from "./checklist";
import actionNote from "./actionNote";
import expense from "./expense";
import SvcMachineType from "./svcMachineType";
import SvcMachineInstance from "./svcMachineInstance";
import ManageSparePart from "./manageSparePart";
import discussion from "./discussion";
import breakdownMaintenance from "./breakdownMaintenance";
import installationCommissioning from "./installationCommissioning";
import svcExpenseList from "./svcExpenseList";
import fileUpload from "./fileUpload";
import actionTicket from "./actionTicket";
import maintenanceClient from "./maintenanceClient";
import comment from "./comments";
import RequestSpareSlice from "./requestSparePart";
import workLog from "./workLog";
import feedBack from "./feedBack";
import resourceViewSlice from "./resourceView";
import bills from "./bills";
import reducerFe from "./fe";
import reducerSalesPipeline from "./sales-pipeline";
import billsInvoice from "./billsInvoice";
import generateInvoice from "./generateInvoice";
import SubscriptionRequest from "./subscription-request";
import generateEInvoice from "./generateEInvoice";
import invoiceItemMaster from "./invoiceItemMaster";
import finalInvoice from "./finalInvoice";
import creditNote from "./creditNote";
import clientMaster from "./clientMaster";
import clientSpoc from "./clientSpoc";
import manualPayment from "./payments/manual-payment";
import invoiceItemMasterScreen from "./invoiceItemMasterScreen";
import socketSlice from "./socket";
import supplier from "./supplier";
import draftPOReducer from "./manage-po";
import requisition from "./manage-requisition";
import organizationScreen from "./organizationScreen";
import checkListView from "./checkListView";

const reducers = combineReducers({
  theme: Theme,
  auth: LoginResponse,
  manageRole: ManageRoleReducer,
  manageUser: ManageUserReducer,
  feature: FeatureReducer,
  permissions: Permissions,
  sidemenu: SideMenuSlice,
  privilege: Privilege,
  sensorProduct: SensorProduct,
  sensorInstance: SensorInstance,
  sensorData: SensorData,
  sensorAnalytics: SensorAnalytics,
  cqrsData: CqrsData,
  notifications: notification,
  client: client,
  ticket: ticket,
  masterConfig: masterConfig,
  manageSubscriber: ManageSubscribersReducer,
  serviceProvider: ServiceProviderReducer,
  document: Document,
  feActivity: feActivity,
  questions: questions,
  device: device,
  fireExtinguisher: FireExtinguisherReducer,
  location: Location,
  organization: organization,
  assetType: assetType,
  plant: plant,
  unit: unit,
  siteManage: siteManage,
  asset: asset,
  systemticket: systemticketSlice,
  sensorInstanceUnit: sensorInstanceUnit,
  preventiveMaintenance: preventiveMaintenance,
  preSaleMaintenence: preSaleMaintenence,
  machine: machine,
  spareParts: spareParts,
  checklist: checklist,
  actionNote: actionNote,
  expense: expense,
  svcMachineType: SvcMachineType,
  svcMachineInstance: SvcMachineInstance,
  manageSparePart: ManageSparePart,
  discussion: discussion,
  breakdownMaintenance: breakdownMaintenance,
  installationCommissioning: installationCommissioning,
  svcExpenseList: svcExpenseList,
  fileUpload: fileUpload,
  actionTicket: actionTicket,
  maintenanceClient: maintenanceClient,
  comment: comment,
  requestedSparePart: RequestSpareSlice,
  workLog: workLog,
  feedBack: feedBack,
  resourceView: resourceViewSlice,
  subscriptionRequest: SubscriptionRequest,
  bills: bills,
  generateInvoice: generateInvoice,
  generateEInvoice: generateEInvoice,
  invoiceItemMaster: invoiceItemMaster,
  finalInvoice: finalInvoice,
  clientMaster: clientMaster,
  ...reducerFe,
  ...reducerSalesPipeline,
  billsInvoice: billsInvoice,
  creditNote: creditNote,
  clientSpoc: clientSpoc,
  manualPayment: manualPayment,
  invoiceItemMasterScreen: invoiceItemMasterScreen,
  socket: socketSlice,
  supplier: supplier,
  draftPO: draftPOReducer,
  requisition: requisition,
  organizationScreen: organizationScreen,
  checkListView: checkListView,
});

export default reducers;
